if (document.readyState !== 'loading') {
    handleHeader();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        handleHeader();
    });
}
function findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el.previousElementSibling;
}
function handleHeader() {
    const headers = document.querySelectorAll('.cmp-header');

    headers.forEach(function (header) {
        const overlay = header.querySelector('.cmp-header__overlay');
        const logo = header.querySelector('.cmp-header__logo');
        const mainMenu = header.querySelector('.cmp-header__main-nav');
        const genericNavMenu = header.querySelector('.cmp-header__generic-nav-list-wrapper .navigation-generic__head');

        const firstLevelSubmenus = header.querySelectorAll('.cmp-header__main-nav > .navigation-main > .cmp-navigation > .cmp-navigation__group > .cmp-navigation__item > .cmp-navigation__group');
        const firstLevelMenuItems = header.querySelectorAll('.cmp-header__main-nav > .navigation-main > .cmp-navigation > .cmp-navigation__group > .cmp-navigation__item');
        const secondLevelMenuItems = header.querySelectorAll('.cmp-header__main-nav > .navigation-main > .cmp-navigation > .cmp-navigation__group > .cmp-navigation__item > .cmp-navigation__group > .cmp-navigation__item');

        const passwordButtons = header.querySelectorAll('.cmp-header__auth-show-password');
        const showRegisterButton = header.querySelector('.cmp-header__auth-menu-item--registration');
        const showLoginButton = header.querySelector('.cmp-header__auth-menu-item--login');
        const registerForm = header.querySelector('.cmp-header__auth-register');
        const loginForm = header.querySelector('.cmp-header__auth-login');

        const genericNavMenuItems = header.querySelectorAll('.cmp-header__generic-nav .cmp-navigation__item');

        const authShowButton = header.querySelector('.cmp-header__generic-nav-more > .cmp-header__generic-nav-more-item > .cmp-header__generic-nav-more-link--user');
        const authWrapper = header.querySelector('.cmp-header__auth');
        const authOverlay = header.querySelector('.cmp-header__generic-nav-auth-overlay');

        const loggedInButton = header.querySelector('#header-logged-in > .cmp-header__generic-nav-more-link--user');
        const loggedInWrapper = header.querySelector('#header-logged-in .cmp-header__generic-nav-logged-in-menu-wrapper');
        const logOutButtons = header.querySelectorAll('.cmp-header__generic-nav-logged-in-menu-item.logout a');
        const requiresLoginLinkList = header.querySelectorAll('.requires-login');

        const searchButton = header.querySelector('.cmp-header__generic-nav-more-link--search');
        const searchWrapper = header.querySelector('.cmp-header__generic-nav-search-wrapper');
        const searchBlock = header.querySelector('.cmp-header__search');
        const searchInput = header.querySelector('.cmp-header__search-input');
        const searchAutocomplete = header.querySelector('.cmp-header__search-autocomplete');
        const searchSuggestions = header.querySelector('.cmp-header__search-suggestion');
        const searchSuggestionsWrapper = header.querySelector('.cmp-header__search-suggestion-wrapper');
        const searchSuggestionsLabel = header.querySelector('.cmp-header__search-suggestion-label');
        const searchSuggestionsList = header.querySelector('.cmp-header__search-suggestion-list');
        const searchInnerButton = header.querySelector('.cmp-header__search-button');
        const searchTopline = header.querySelector('.cmp-header__search-top-line');

        const mobileMenuButton = header.querySelector('.cmp-header__main-nav-mobile-btn');
        const mobileMenu = header.querySelector('.cmp-header__main-nav');
        const mobileMenuCloseButton = header.querySelector('.cmp-header__main-nav-mobile-close');
        const mobileGenericMenu = header.querySelector('.cmp-header__generic-nav-list-wrapper');
        const mobileGenericButton = header.querySelector('.cmp-header__generic-nav-mobile-btn');
        const mobileGenericCloseButton = header.querySelector('.cmp-header__generic-nav-mobile-close');

        const mobileAuthButton = header.querySelector('.cmp-header__main-nav-link--btn-mobile-auth.is-logged-in');

        const navigationOverlayLv = header.querySelector('.cmp-header__navigation-overlay-lv');
        const navigationOverlayLvShowAll = header.querySelectorAll('.navigation-generic .cmp-navigation__item:nth-child(3) .cmp-navigation__item-link');

        const loginButtonDesktop = header.querySelector('.cmp-header__generic-nav-more-item:last-child');
        const loginButtonMobile = header.querySelector('.cmp-header__main-nav-link--btn-mobile-auth:not(.is-logged-in)');

        const setActiveTenantLink = () => {
            const tenantMeta = document.querySelector('meta[property="tenant"]');
            let currentTenant = tenantMeta ? tenantMeta.getAttribute('content') : 'tennisde';
            if (!currentTenant || currentTenant === 'tennis') {
                currentTenant = 'tennisde';
            }

            const activeGenericNavMenuItem = header.querySelectorAll('.cmp-header__generic-nav .cmp-navigation__item.cmp-navigation__item--active');
            const activeGenericNavMenuItemText = activeGenericNavMenuItem.length > 0 ? activeGenericNavMenuItem[0].querySelector('.cmp-navigation__item-link').textContent : '';

            if (activeGenericNavMenuItemText === 'Weitere Plattformen') {
                return;
            }

            genericNavMenuItems.forEach(menuItem => {
                menuItem.classList.remove('cmp-navigation__item--active');
            });

            const activateMenuItems = (matchingText) => {
                genericNavMenuItems.forEach(menuItem => {
                    const menuLink = menuItem.querySelector('.cmp-navigation__item-link');
                    if (menuLink && menuLink.textContent === matchingText) {
                        menuItem.classList.add('cmp-navigation__item--active');
                    }
                });
            };

            const tenantToMenuText = {
                'tennisde': 'tennis.de',
                'dtb': 'DTB',
                'default': 'Landesverbände'
            };

            const matchingText = tenantToMenuText[currentTenant] || tenantToMenuText['default'];

            if (['tennisde', 'dtb'].includes(currentTenant)) {
                activateMenuItems(matchingText);
            } else {
                const tenantList = navigationOverlayLv.querySelectorAll('.cmp-list__item-name');
                const tenantNames = Array.from(tenantList, tenant => tenant.textContent);
                if (tenantNames.includes(currentTenant)) {
                    activateMenuItems(tenantToMenuText['default']);
                }
            }
        };

        setActiveTenantLink();

        const navigationGenericHead = header.querySelector('.cmp-header__generic-nav-list-wrapper');
        const navSearchSuggestions = header.querySelector('.cmp-header__generic-nav-search');
        const searchSuggestionWrapper = header.querySelector('.cmp-header__search-suggestion-wrapper');

        const navSearchSuggestionsClone = navSearchSuggestions.cloneNode(true);
        navSearchSuggestionsClone.classList.remove('cmp-header__generic-nav-search');
        navSearchSuggestionsClone.classList.add('cmp-header__generic-nav-search-mobile');
        const directChildren = Array.from(navSearchSuggestionsClone.children);

        directChildren.forEach(child => {
            child.className = '';
        });
        searchSuggestionWrapper.parentElement.appendChild(navSearchSuggestionsClone);

        const deactivateSubmenus = (menuItems) => {
            menuItems.forEach(function (menuItem) {
                const menuLink = menuItem.querySelector('.cmp-navigation__item-link');
                const submenu = menuLink.nextElementSibling;
                const toggle = menuItem.querySelector('.cmp-header__main-nav-submenu-toggle');

                if (submenu && submenu.classList.contains('cmp-navigation__group')) {
                    menuLink.classList.remove('cmp-navigation__item-link--active');
                    menuLink.classList.remove('cmp-navigation__item-link--expanded');
                    submenu.classList.remove('cmp-navigation__group--active');
                    if (toggle) {
                        toggle.classList.remove('cmp-header__main-nav-submenu-toggle--active');
                    }
                }
            });
        }

        const hideEverything = () => {
            overlay.classList.remove('cmp-header__overlay--active');
            logo.classList.remove('cmp-header__logo--hidden');
            mainMenu.classList.remove('cmp-header__main-nav--hidden');
            mainMenu.classList.remove('cmp-header__main-nav--submenu-active');

            deactivateSubmenus(firstLevelMenuItems);
            deactivateSubmenus(secondLevelMenuItems);

            showRegisterButton.classList.add('cmp-header__auth-menu-item--active');
            showLoginButton.classList.remove('cmp-header__auth-menu-item--active');
            registerForm.classList.add('cmp-header__auth-register--active');
            loginForm.classList.remove('cmp-header__auth-login--active');

            authWrapper.classList.remove('cmp-header__auth--active');
            if (authShowButton) {
                authShowButton.classList.remove('cmp-header__generic-nav-more-link--active');
            }
            authOverlay.classList.remove('cmp-header__generic-nav-auth-overlay--active');

            if (loggedInButton && loggedInWrapper) {
                loggedInWrapper.classList.remove('cmp-header__generic-nav-logged-in-menu-wrapper--active');
                loggedInButton.classList.remove('cmp-header__generic-nav-more-link--active');
            }

            navigationGenericHead.classList.remove('cmp-header__generic-nav-search-wrapper--active');
            searchWrapper.classList.remove('cmp-header__generic-nav-search-wrapper--active');
            searchButton.classList.remove('cmp-header__generic-nav-more-link--active');
            searchBlock.classList.remove('cmp-header__search--active');
            searchInput.value = '';
            searchInput.classList.remove('cmp-header__search-input--active');
            searchAutocomplete.classList.remove('cmp-header__search-autocomplete--active');
            searchAutocomplete.value = '';
            searchTopline.textContent = '';
            searchSuggestions.classList.remove('cmp-header__search-suggestion--active');
            searchSuggestionsWrapper.classList.remove('cmp-header__search-suggestion--active');
            searchSuggestionsLabel.classList.remove('cmp-header__search-suggestion-label--active');
            searchSuggestionsList.innerHTML = '';
            searchInnerButton.classList.remove('cmp-header__search-button--active');

            mobileMenu.classList.remove('cmp-header__main-nav--active');
            mobileMenuButton.classList.remove('cmp-header__main-nav-mobile-btn--active');
            mobileGenericMenu.classList.remove('cmp-header__generic-nav-list-wrapper--active');
            mobileGenericButton.classList.remove('cmp-header__generic-nav-mobile-btn--active');

            navigationOverlayLv.classList.remove('cmp-header__navigation-overlay-lv--active');
            navigationOverlayLvShowAll.forEach(navigationOverlayLvShow => {
                navigationOverlayLvShow.parentElement.classList.remove('cmp-navigation__item--active');
            })
            header.classList.remove('cmp-header__overlay-lv--active');
            header.classList.remove('cmp-header__logged-in--active');
            header.classList.remove('cmp-header__logged-in-first--active');
            header.classList.remove('cmp-header--auth-active');
        }

        // split submenus into two columns
        firstLevelSubmenus.forEach(function (firstLevelSubmenu) {
            const submenusItems = firstLevelSubmenu.querySelectorAll(':scope > .cmp-navigation__item');
            const submenusItemsHalf = Math.ceil(submenusItems.length / 2);
            const firstHalf = firstLevelSubmenu.querySelectorAll(':scope > .cmp-navigation__item:nth-child(-n+' + submenusItemsHalf + ')');
            const secondHalf = firstLevelSubmenu.querySelectorAll(':scope > .cmp-navigation__item:nth-child(n+' + (submenusItemsHalf + 1) + ')');
            const firstHalfList = document.createElement('ul');
            const secondHalfList = document.createElement('ul');
            const listsWrapper = document.createElement('li');

            firstHalfList.classList.add('cmp-header__main-nav-submenu-list');
            secondHalfList.classList.add('cmp-header__main-nav-submenu-list');
            listsWrapper.classList.add('cmp-header__main-nav-submenu-list-wrapper');
            firstHalf.forEach(function (firstHalfItem) {
                firstHalfList.appendChild(firstHalfItem);
            });
            secondHalf.forEach(function (secondHalfItem) {
                secondHalfList.appendChild(secondHalfItem);
            });
            firstLevelSubmenu.appendChild(listsWrapper);
            listsWrapper.appendChild(firstHalfList);
            listsWrapper.appendChild(secondHalfList);
        });

        // show and hide submenu on click
        firstLevelMenuItems.forEach(function (firstLevelItem) {
            const firstLevelLink = firstLevelItem.querySelector('.cmp-navigation__item-link');
            const submenu = firstLevelLink.nextElementSibling;

            if (submenu && submenu.classList.contains('cmp-navigation__group')) {

                firstLevelLink.addEventListener('click', function (e) {
                    e.preventDefault();
                    const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
                    const isActive = firstLevelLink.classList.contains('cmp-navigation__item-link--active');

                    if (viewportWidth < 1024) {
                        if (!isActive) {
                            firstLevelLink.classList.add('cmp-navigation__item-link--active');
                            submenu.classList.add('cmp-navigation__group--active');
                            mainMenu.classList.add('cmp-header__main-nav--submenu-active');
                        } else {
                            firstLevelLink.classList.remove('cmp-navigation__item-link--active');
                            submenu.classList.remove('cmp-navigation__group--active');
                            mainMenu.classList.remove('cmp-header__main-nav--submenu-active');
                        }
                    } else {
                        hideEverything();

                        if (!isActive) {
                            firstLevelLink.classList.add('cmp-navigation__item-link--active');
                            submenu.classList.add('cmp-navigation__group--active');
                            overlay.classList.add('cmp-header__overlay--active');
                        }
                    }
                });

                firstLevelLink.addEventListener('focusin', function (e) {
                    e.preventDefault();
                    if (e.target.matches(':focus-visible')) {
                        const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
                        const isActive = firstLevelLink.classList.contains('cmp-navigation__item-link--active');

                        if (viewportWidth < 1024) {
                            if (!isActive) {
                                firstLevelLink.classList.add('cmp-navigation__item-link--active');
                                submenu.classList.add('cmp-navigation__group--active');
                            } else {
                                firstLevelLink.classList.remove('cmp-navigation__item-link--active');
                                submenu.classList.remove('cmp-navigation__group--active');
                            }
                        } else {
                            hideEverything();

                            if (!isActive) {
                                firstLevelLink.classList.add('cmp-navigation__item-link--active');
                                submenu.classList.add('cmp-navigation__group--active');
                                overlay.classList.add('cmp-header__overlay--active');
                            }
                        }
                    }
                });
            }
        });
        const backToPrevMenu = function () {
            const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

            if (viewportWidth < 1024) {
                const submenu = this.closest('.cmp-navigation__group');
                const submenuParent = submenu.closest('.cmp-navigation__item');
                const submenuLink = submenuParent.querySelector(':scope > .cmp-navigation__item-link');
                submenuLink.classList.remove('cmp-navigation__item-link--active');
                submenu.classList.remove('cmp-navigation__group--active');
                mainMenu.classList.remove('cmp-header__main-nav--submenu-active');
                deactivateSubmenus(secondLevelMenuItems);
            } else {
                hideEverything();
            }
        }
        // add nav close buttons and titles to submenus
        firstLevelSubmenus.forEach(function (firstLevelSubmenu) {
            const submenuCloseButton = document.createElement('li');
            submenuCloseButton.classList.add('cmp-header__main-nav-submenu-close');
            submenuCloseButton.innerHTML = '<button type="button" class="cmp-header__main-nav-submenu-close-btn">Schließen</button>';
            firstLevelSubmenu.insertBefore(submenuCloseButton, firstLevelSubmenu.firstChild);
            const submenuTitle = document.createElement('li');
            const submenuLinkText = firstLevelSubmenu.closest('.cmp-navigation__item').querySelector(':scope > .cmp-navigation__item-link').innerText;
            submenuTitle.classList.add('cmp-header__main-nav-submenu-title-wrapper');
            submenuTitle.innerHTML = `<h2 class="cmp-header__main-nav-submenu-title">${submenuLinkText}</h2>`;
            firstLevelSubmenu.insertBefore(submenuTitle, firstLevelSubmenu.firstChild);

            submenuCloseButton.addEventListener('click', backToPrevMenu);
            firstLevelSubmenu.querySelector('.cmp-header__main-nav-submenu-title').addEventListener('click', backToPrevMenu);
        });

        secondLevelMenuItems.forEach(function (secondLevelItem) {
            const secondLevelLink = secondLevelItem.querySelector('.cmp-navigation__item-link');
            secondLevelLink.addEventListener('focusin', (e) => {
                if (e.target.matches(':focus-visible')) {
                    const firstLevelNavLink = findAncestor(secondLevelLink, 'cmp-navigation__group')
                    if (!firstLevelNavLink.classList.contains('cmp-navigation__item-link--active')) firstLevelNavLink.click()
                }
            })
            const submenu = secondLevelLink.nextElementSibling

            if (submenu && submenu.classList.contains('cmp-navigation__group')) {


                const thirdLevelLinks = submenu.querySelectorAll('.cmp-navigation__item-link')
                thirdLevelLinks.forEach((thirdLevelLink) => {
                    thirdLevelLink.addEventListener('focusin', (e) => {
                        if (e.target.matches(':focus-visible')) {
                            const firstLevelNavLink = findAncestor(secondLevelLink, 'cmp-navigation__group')
                            if (!firstLevelNavLink.classList.contains('cmp-navigation__item-link--active')) firstLevelNavLink.click()
                        }
                    })
                    thirdLevelLink.addEventListener('focus', () => {
                        const isActive = thirdLevelLink.parentElement.parentElement.parentElement.classList.contains('cmp-navigation__item-link--expanded');
                        deactivateSubmenus(secondLevelMenuItems);
                        if (!isActive) {
                            secondLevelLink.classList.add('cmp-navigation__item-link--expanded');
                            submenu.classList.add('cmp-navigation__group--active');
                            secondLevelToggle.classList.add('cmp-header__main-nav-submenu-toggle--active');
                        }
                    })
                })


                const secondLevelToggle = document.createElement('button');
                secondLevelToggle.classList.add('cmp-header__main-nav-submenu-toggle');
                secondLevelToggle.textContent = 'Untermenü erweitern';
                secondLevelToggle.setAttribute('tabindex', '-1')
                secondLevelItem.appendChild(secondLevelToggle);

                secondLevelLink.removeAttribute('href')
                secondLevelLink.setAttribute('tabindex', '0')

                function handleSecondLevelInteraction() {
                    const isActive = secondLevelLink.classList.contains('cmp-navigation__item-link--expanded');
                    deactivateSubmenus(secondLevelMenuItems);
                    if (!isActive) {
                        secondLevelLink.classList.add('cmp-navigation__item-link--expanded');
                        submenu.classList.add('cmp-navigation__group--active');
                        secondLevelToggle.classList.add('cmp-header__main-nav-submenu-toggle--active');
                    }
                }

                secondLevelLink.addEventListener('click', handleSecondLevelInteraction);
                secondLevelLink.addEventListener('keyup', (e) => {
                    if (e.keyCode === 13 || e.keyCode === 32) {
                        handleSecondLevelInteraction();
                    }
                });

                // secondLevelLink.addEventListener('focus', handleSecondLevelInteraction);
            }
        });

        // checking submenu height on mobile
        setInterval(function () {
            const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

            if (viewportWidth < 1024) {
                const activeSubmenu = header.querySelector('.cmp-navigation__group--active');

                if (activeSubmenu) {
                    const activeSubmenuHeight = activeSubmenu ? activeSubmenu.offsetHeight : 0;
                    mobileMenu.style.height = activeSubmenuHeight + 'px';

                } else {
                    mobileMenu.style.height = 'auto';
                }
            }
        }, 100);

        // show/hide password buttons
        passwordButtons.forEach(function (passwordButton) {
            const passwordInput = passwordButton.previousElementSibling;

            passwordButton.addEventListener('click', function () {
                const passwordInputType = passwordInput.getAttribute('type');
                if (passwordInputType === 'password') {
                    passwordInput.setAttribute('type', 'text');
                    passwordButton.classList.add('cmp-header__auth-show-password--active');
                } else {
                    passwordInput.setAttribute('type', 'password');
                    passwordButton.classList.remove('cmp-header__auth-show-password--active');
                }
            });
        });

        // switch between login and register forms
        showRegisterButton.addEventListener('click', function () {
            registerForm.classList.add('cmp-header__auth-register--active');
            loginForm.classList.remove('cmp-header__auth-login--active');
            showRegisterButton.classList.add('cmp-header__auth-menu-item--active');
            showLoginButton.classList.remove('cmp-header__auth-menu-item--active');
        });

        showLoginButton.addEventListener('click', function () {
            loginForm.classList.add('cmp-header__auth-login--active');
            registerForm.classList.remove('cmp-header__auth-register--active');
            showLoginButton.classList.add('cmp-header__auth-menu-item--active');
            showRegisterButton.classList.remove('cmp-header__auth-menu-item--active');
        });

        // show/hide logged in menu
        function getCookie(name) {
            const cookies = document.cookie.split('; ');
            for (let cookie of cookies) {
                const [key, value] = cookie.split('=');
                if (key === name) {
                    return value;
                }
            }
            return null;
        }

        function decodeBase64Utf8(base64) {
            try {
                const binary = atob(base64);
                const utf8Decoder = new TextDecoder('utf-8');
                const binaryArray = new Uint8Array(binary.split('').map(char => char.charCodeAt(0)));
                return utf8Decoder.decode(binaryArray);
            } catch (error) {
                return null;
            }
        }

        const tdeloginCookie = getCookie('tdelogin');
        let userFirstname = null;

        const userMenuOverlay = document.createElement('div');
        const userMenuOverlayInner = document.createElement('div');
        userMenuOverlay.classList.add('cmp-header__generic-nav-user-menu-overlay');
        userMenuOverlayInner.classList.add('cmp-header__generic-nav-user-menu-overlay-inner');

        if (tdeloginCookie) {
            try {
                const decodedData = decodeBase64Utf8(tdeloginCookie);
                if (decodedData) {
                    const userData = JSON.parse(decodedData);

                    userFirstname = userData.firstname || null;
                    userMenuOverlayInner.textContent = 'Willkommen ' + userFirstname + '!';
                } else {
                    userMenuOverlayInner.textContent = 'Willkommen!';
                }
            } catch (error) {
                console.error('Error parsing cookie:', error);
                userMenuOverlayInner.textContent = 'Willkommen!';
            }
        }
        genericNavMenu.appendChild(userMenuOverlay);
        userMenuOverlay.appendChild(userMenuOverlayInner);

        if (loggedInButton && loggedInWrapper) {
            loggedInButton.addEventListener('click', function () {
                const isActive = loggedInWrapper.classList.contains('cmp-header__generic-nav-logged-in-menu-wrapper--active');
                hideEverything();

                if (!isActive) {
                    loggedInWrapper.classList.add('cmp-header__generic-nav-logged-in-menu-wrapper--active');
                    loggedInButton.classList.add('cmp-header__generic-nav-more-link--active');
                    header.classList.add('cmp-header__logged-in--active');
                }
            });

            window.onclick = function (event) {
                const isActive = loggedInWrapper.classList.contains('cmp-header__generic-nav-logged-in-menu-wrapper--active');

                if (isActive && !loggedInWrapper.contains(event.target) && event.target !== loggedInButton) {
                    hideEverything();
                }
            }

            requiresLoginLinkList.forEach(function (requiresLoginLink) { requiresLoginLink.classList.remove('requires-login-hidden');})
        }

        // create overlay for auth menu
        const loggedInMobileOverlay = document.createElement('div');
        loggedInMobileOverlay.classList.add('cmp-header__logged-in-mobile-overlay');
        const loggedInMobileOverlayButton = document.createElement('button');
        loggedInMobileOverlayButton.classList.add('cmp-header__logged-in-mobile-overlay-button');
        loggedInMobileOverlayButton.textContent = 'zurück';
        loggedInMobileOverlay.appendChild(loggedInMobileOverlayButton);
        header.appendChild(loggedInMobileOverlay);

        loggedInMobileOverlayButton.addEventListener('click', function () {
            header.classList.remove('cmp-header--auth-active');
        });

        if (mobileAuthButton) {
            mobileAuthButton.addEventListener('click', function () {
                const isActive = authWrapper.classList.contains('cmp-header--auth-active');

                if (!isActive) {
                    header.classList.add('cmp-header--auth-active');
                    overlay.classList.add('cmp-header__overlay--active');
                }
            });
        }

        // show search form
        searchButton.addEventListener('click', function () {
            const isActive = searchWrapper.classList.contains('cmp-header__generic-nav-search-wrapper--active');
            hideEverything();

            if (!isActive) {
                overlay.classList.add('cmp-header__overlay--active');
                searchWrapper.classList.add('cmp-header__generic-nav-search-wrapper--active');
                navigationGenericHead.classList.add('cmp-header__generic-nav-search-wrapper--active');
                searchButton.classList.add('cmp-header__generic-nav-more-link--active');
                searchBlock.classList.add('cmp-header__search--active');
                mainMenu.classList.add('cmp-header__main-nav--hidden');
                searchInput.focus();
            }
        });

        // search form topline
        searchInput.addEventListener('keyup', function () {
            searchTopline.textContent = searchInput.value;
        });

        // search autocomplete
        searchInput.addEventListener('keyup', function () {
            if (searchInput.value.length > 0) {
                searchInput.classList.add('cmp-header__search-input--active');
                searchInnerButton.classList.add('cmp-header__search-button--active');
            } else {
                searchInput.classList.remove('cmp-header__search-input--active');
                searchInnerButton.classList.remove('cmp-header__search-button--active');
            }
        });

        // hide everything on overlay click
        overlay.addEventListener('click', function () {
            hideEverything();
        });

        // show/hide mobile menu
        mobileMenuButton.addEventListener('click', function () {
            const isActive = mobileMenu.classList.contains('cmp-header__main-nav--active');
            hideEverything();

            if (!isActive) {
                mobileMenu.classList.add('cmp-header__main-nav--active');
                mobileMenuButton.classList.add('cmp-header__main-nav-mobile-btn--active');
                overlay.classList.add('cmp-header__overlay--active');
            }
        });

        mobileMenuCloseButton.addEventListener('click', function () {
            hideEverything();
        });

        // show/hide mobile generic menu
        mobileGenericButton.addEventListener('click', function () {
            const isActive = mobileGenericMenu.classList.contains('cmp-header__generic-nav-list-wrapper--active');
            hideEverything();

            if (!isActive) {
                mobileGenericMenu.classList.add('cmp-header__generic-nav-list-wrapper--active');
                mobileGenericButton.classList.add('cmp-header__generic-nav-mobile-btn--active');
                overlay.classList.add('cmp-header__overlay--active');
            }
        });

        mobileGenericCloseButton.addEventListener('click', function () {
            hideEverything();
        });

        // navigation overlay lv
        if (navigationOverlayLv !== null && navigationOverlayLvShowAll.length !== 0) {
            const overlayHeader = document.createElement('div');
            overlayHeader.classList.add('cmp-header__generic-nav-overlay-lv-header');

            const overlayHeaderTitle = document.createElement('h2');
            overlayHeaderTitle.classList.add('cmp-header__generic-nav-overlay-lv-title');
            overlayHeaderTitle.textContent = 'Landesverbände';
            overlayHeader.appendChild(overlayHeaderTitle);

            const overlayHeaderLink = document.createElement('a');
            overlayHeaderLink.classList.add('cmp-header__generic-nav-overlay-lv-button', 'v-button', 'v-button--secondary', 'v-button--medium');
            if (window.location.hostname.includes('tennis.de')) {
                overlayHeaderLink.setAttribute('href', '/lv-uebersicht.html');
            } else {
                overlayHeaderLink.setAttribute('href', '/content/tennis/lv-uebersicht.html');
            }
            overlayHeaderLink.innerHTML = '<span>Übersicht</span>';
            overlayHeader.appendChild(overlayHeaderLink);

            const overlayHeaderClose = document.createElement('button');
            overlayHeaderClose.classList.add('cmp-header__generic-nav-overlay-lv-close');
            overlayHeaderClose.textContent = 'Schließen';
            overlayHeader.appendChild(overlayHeaderClose);

            const overlayHeaderMobileBack = document.createElement('button');
            overlayHeaderMobileBack.classList.add('cmp-header__generic-nav-overlay-lv-mobile-back');
            overlayHeaderMobileBack.textContent = 'Landesverbände';
            overlayHeader.appendChild(overlayHeaderMobileBack);

            navigationOverlayLv.prepend(overlayHeader);

            setOverlayPadding();

            window.addEventListener('resize', function () {
                if (logo.classList.contains('nav-overlay-active')) logo.classList.remove('nav-overlay-active')
                setOverlayPadding();
            });

            // Overlay actions
            navigationOverlayLvShowAll.forEach(navigationOverlayLvShow => {
                navigationOverlayLvShow.classList.add('item-link-submenu')
                navigationOverlayLvShow.addEventListener('click', function (e) {
                    e.preventDefault();
                    const isActive = navigationOverlayLvShow.parentElement.classList.contains('cmp-navigation__item--active');
                    hideEverything();

                    if (!isActive) {
                        document.body.style.overflow = 'hidden'
                        navigationOverlayLv.classList.add('cmp-header__navigation-overlay-lv--active');
                        navigationOverlayLvShow.parentElement.classList.add('cmp-navigation__item--active');
                        header.classList.add('cmp-header__overlay-lv--active');
                        overlay.classList.add('cmp-header__overlay--active');
                    }
                });
            })

            const allOverviewLinks = document.querySelectorAll('.cmp-navigation__item-overview.cmp-navigation__item--level-2');

            allOverviewLinks.forEach((overviewLink) => {
                if (overviewLink.classList.contains('cmp-navigation__item--active')) {
                    const overviewLinkHref = new URL(overviewLink.querySelector('.cmp-navigation__item-link').href).pathname;
                    const currentPathname = window.location.pathname;

                    if (overviewLinkHref !== currentPathname) {
                        overviewLink.classList.remove('cmp-navigation__item--active');
                    }
                }
            });

            overlayHeaderClose.addEventListener('click', function (e) {
                document.body.style.overflow = 'auto'
                logo.classList.remove('nav-overlay-active')
                e.preventDefault();
                hideEverything();
            });

            overlayHeaderMobileBack.addEventListener('click', function (e) {
                document.body.style.overflow = 'auto'
                logo.classList.remove('nav-overlay-active')
                e.preventDefault();
                hideEverything();
                mobileMenu.classList.add('cmp-header__main-nav--active');
                mobileMenuButton.classList.add('cmp-header__main-nav-mobile-btn--active');
                overlay.classList.add('cmp-header__overlay--active');
            });

            function setOverlayPadding() {
                const headerHeight = header.offsetHeight;
                if (window.innerWidth < 1024) {
                    navigationOverlayLv.style.paddingTop = (headerHeight + 50) + 'px';
                } else {
                    navigationOverlayLv.style.paddingTop = (headerHeight - 40) + 'px';
                }
            }
        }
        // header scroll functionallity
        let previousScrollPosition = window.scrollY;
        header.parentElement.style.height = header.clientHeight + 'px'

        window.addEventListener('resize', () => {
            header.parentElement.style.height = header.clientHeight + 'px'
        })

        const handleScroll = () => {
            if (window.scrollY > 500) {
                header.classList.add('header-small')
            } else {
                header.classList.remove('header-small')
            }

            if (header.classList.contains('header-small')) {
                const currentScrollPosition = window.scrollY;
                header.style.transform = `translateY(${currentScrollPosition > previousScrollPosition ? '-100%' : '0'})`

                if (currentScrollPosition > previousScrollPosition) {
                    header.classList.add('header-hidden')
                } else {
                    header.classList.remove('header-hidden')
                }

                previousScrollPosition = currentScrollPosition;
            } else {
                header.classList.remove('header-hidden');
                header.style.transform = 'translateY(0)';
                header.parentElement.style.height = header.clientHeight + 'px';
                setTimeout(() => {
                    header.parentElement.style.height = header.clientHeight + 'px';
                }, 200);
            }
        };

        let scrolling = false;

        const throttleScroll = () => {
            if (!scrolling) {
                handleScroll();
                scrolling = true;
                setTimeout(() => {
                    scrolling = false;
                }, 10); // Adjust the interval as needed
            }
        };

        window.addEventListener('scroll', throttleScroll);
        handleScroll();

        const element = document.querySelector('.cmp-header__navigation-overlay-lv');

        if (element) {
            element.addEventListener('scroll', () => {
                element && element.scrollTop > 0 && window.innerWidth < 1024 ? logo.classList.add('nav-overlay-active') : logo.classList.remove('nav-overlay-active')
            });
        }

        // logout
        logOutButtons.forEach(function (logOutButton) {
            logOutButton.addEventListener('click', function () {
                const homeUrl = header.querySelector('.cmp-header__logo a').href;
                document.cookie = "tdelogin=; expires=Thu, 01-Jan-70 00:00:01 GMT; path=/; domain=.tennis.de;"
                window.location.href = homeUrl;
            });
        });

        // add cookie before login
        const addPageCookie = () => {
            let endDate = new Date();
            endDate.setTime(endDate.getTime() + 300000);

            document.cookie = "tderedirect=" + encodeURIComponent(window.location.href) +
                "; expires=" + endDate.toUTCString() +
                "; path=/; domain=.tennis.de; secure";

        }

        if (loginButtonDesktop) {
            loginButtonDesktop.addEventListener('click', () => addPageCookie());
        }

        if (loginButtonMobile) {
            loginButtonMobile.addEventListener('click', () => addPageCookie());
        }

        // check first page after login
        const firstLoginCookie = getCookie('tdefirstlogin');

        if (firstLoginCookie) {
            document.cookie = "tdefirstlogin=; expires=Thu, 01-Jan-70 00:00:01 GMT; path=/; domain=.tennis.de;"
            if (loggedInWrapper && loggedInButton && header) {
                header.classList.add('cmp-header__logged-in-first--active');

                setTimeout(() => {
                    hideEverything();
                }, 3000)
            }
        }
    });
}
